<template>
  <div class="container">
    <div class="section mb10">
      <!-- <div class="section-hd" />
      <div class="section-bd" /> -->
      <div class="section-title">
        派件员：{{ express.courier }}
      </div>
      <div class="section-txt">
        手机号：{{ express.courierPhone }}
      </div>
    </div>
    <div class="section2 mb10">
      <div class="section2-media">
        <van-image class="section2-media-img" :src="express.logo" />
      </div>
      <div class="section2-inner">
        <div class="section2-title">承运来源：{{ express.expName }}</div>
        <div class="section2-txt">运单编号：{{ express.number }}</div>
        <div class="section2-txt">官方电话：{{ express.expPhone }}</div>
      </div>
    </div>
    <div class="main">
      <div class="main-h2">物流跟踪</div>
      <van-steps direction="vertical" :active="0">
        <van-step v-for="(item,index) in list" :key="index">
          <h3>{{ item.status }}</h3>
          <p>{{ item.time }}</p>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import { Step, Steps } from 'vant'

export default {
  name: 'Express',
  components: {
    [Step.name]: Step,
    [Steps.name]: Steps
  },
  mixins: [PageMixin],
  data() {
    return {
      express: {},
      list: []
    }
  },
  created() {
    this.onExperss()
  },
  methods: {
    onExperss() {
      this.$api.order_express({ order_id: this.$route.query.order_id }).then(res => {
        this.express = res.data || {}
        this.list = res.data.list || []
      })
    }
  }
}
</script>

<style lang="less" scoped>

  .section{
    padding: 10px 15px;
    background: #fff;
  }

  .section2{
    padding: 10px 15px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-media{
      margin-right: 10px;
      &-img{
        width: 50px;
        height: 50px;
      }
    }
    &-title{
      font-size: 14px;
      color: #333;
      margin-bottom: 5px;
    }
    &-txt{
      font-size: 12px;
      color: #666;
    }
  }
  .main{
    background: #fff;
    &-h2{
      padding: 10px 15px 0;
      font-size: 14px;
      color: #333;
      margin-bottom: 5px;
    }
  }
</style>

